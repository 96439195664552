import React from 'react';
import {
    Create,
    SimpleForm,
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    BooleanInput
} from 'react-admin';

export const TaskCreate = ({permissions, ...props}) => (
    <Create title="Create task" {...props}>
        <SimpleForm>
            {permissions === 'admin' &&
            <ReferenceInput label="Employee" source="employee.id" reference="employees">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>}
            <ReferenceInput label="Machine" source="machine.id" reference="machines">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput label="Fuel container type" source="fuel_container.id" reference="fuel-containers">
                <AutocompleteInput optionText="type"/>
            </ReferenceInput>
            <BooleanInput source="greasing"/>
            <NumberInput label="Off Road Diesel" source="used_fluids.off_road_diesel"/>
            <NumberInput label="DEF" source="used_fluids.def"/>
            <NumberInput label="Antifreeze" source="used_fluids.antifreeze"/>
            <NumberInput label="Motor Oil" source="used_fluids.motor_oil"/>
            <NumberInput label="Hydraulic Oil" source="used_fluids.hydraulic_oil"/>
            <DateTimeInput source="time_open"/>
            <DateTimeInput source="time_close"/>
            <NumberInput source="longitude" />
            <NumberInput source="latitude" />
        </SimpleForm>
    </Create>
);
