import React from 'react';
import Chip from '@material-ui/core/Chip';

const roleChoices = [
    {id: 1, name: 'Admin'},
    {id: 2, name: 'User'}
];

const styles = {
    main: { display: 'flex', flexWrap: 'wrap' },
    chip: { margin: 4 },
};

const RolesField = ({ record }) => {
    return (
    <span style={styles.main}>
        {record.roles &&
        record.roles.map(role => (
            <Chip
                key={typeof role === 'object' ? role.id : role}
                style={styles.chip}
                label={roleChoices.find(r => r.id === role || r.id === role.id).name}
            />
        ))}
    </span>
)};

RolesField.defaultProps = {
    addLabel: true,
    source: 'roles',
};

export default RolesField;
