import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    TextInput,
    DisabledInput,
    ReferenceInput,
    ShowButton,
    EditButton,
    ChipField,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    NumberInput,
    AutocompleteInput,
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const dataForExport = entities.map(entity => {
        entity.email = entity.user.email;
        return entity;
    });

    const csv = convertToCSV({
        data: dataForExport,
        fields: ['id', 'user_id', 'name', 'phone_number', 'email', 'created_at', 'updated_at']
    });

    downloadCSV(csv, 'employees_' + dateFormat);
};

export const EmployeeList = props => (
    <List {...props} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField label="ID#" source="id"/>
            <TextField label="Name" source="name"/>
            <TextField label="Phone number" source="phone_number"/>
            <ReferenceField label="User email" source="user_id" reference="users" linkType="show">
                <TextField source="email"/>
            </ReferenceField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const EmployeeShow = (props) => (
    <Show title="Employee view" {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField label="Name" source="name"/>
            <ReferenceField label="User email" source="user_id" reference="users" linkType="show">
                <TextField source="email"/>
            </ReferenceField>
            <TextField label="Phone number" source="phone_number"/>
            <ReferenceManyField label="Tasks" reference="tasks" target="employee_id">
                <SingleFieldList linkType="show">
                    <ChipField source="jobsite.address"/>
                </SingleFieldList>
            </ReferenceManyField>
            <ReferenceManyField label="Jobs" reference="jobs" target="employees.id">
                <SingleFieldList linkType="show">
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceManyField>
            <ReferenceManyField label="Jobsites" reference="jobsites" target="employees.id">
                <SingleFieldList linkType="show">
                    <ChipField source="address"/>
                </SingleFieldList>
            </ReferenceManyField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const EmployeeCreate = (props) => (
    <Create title="Create employee" {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <NumberInput source="phone_number"/>
            <ReferenceInput label="User" source="user_id" reference="users" target="employee_id"
                            filter={{employeeAttached: 'exclude'}}>
                <AutocompleteInput optionText="email"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const EmployeeEdit = (props) => (
    <Edit title="Employee edit" {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="name"/>
            <TextInput source="phone_number"/>
            <ReferenceInput label="User" source="user_id" reference="users">
                <AutocompleteInput optionText="email"/>
            </ReferenceInput>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleForm>
    </Edit>
);
