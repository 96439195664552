import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    EmailField,
    TextInput,
    DisabledInput,
    ShowButton,
    EditButton,
    SelectArrayInput,
    email
} from 'react-admin';
import RolesField from './fields/RolesField';

export const UserList = props => (
    <List {...props} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <EmailField source="email"/>
            <RolesField labe="Roles" source="roles"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const UserShow = (props) => (
    <Show title="User view" {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <EmailField source="email"/>
            <RolesField labe="Roles" source="roles"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

const validateUserCreation = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = ['The email is required!'];
    }
    if (!values.password) {
        errors.password = ['The password is required'];
    } else if (values.password.length < 6) {
        errors.password = ['The password cannot have less than 6 characters'];
    }
    return errors
};

const validateEmail = email();

export const UserCreate = (props) => (
    <Create title="Create user" {...props} undoable={false}>
        <SimpleForm validate={validateUserCreation}>
            <TextInput source="email" validate={validateEmail}/>
            <TextInput source="password"/>
            <SelectArrayInput label="Roles" source="roles" translateChoice={false} choices={[
                {id: '1', name: 'Admin'},
                {id: '2', name: 'User'}
            ]}/>
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit title="User edit" {...props} undoable={false}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="email"/>
            <TextInput source="password"/>
            <SelectArrayInput label="Roles" source="roles" translateChoice={false} optionText="name" optionValue="id" choices={[
                {id: 1, name: 'Admin'},
                {id: 2, name: 'User'}
            ]}/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleForm>
    </Edit>
);
