import React from "react";
import {
    Datagrid,
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    ReferenceField,
    ReferenceManyField,
    NumberField,
    BooleanField
} from 'react-admin';
import CustomMachineField from "./customMachineField";

export const TaskShow = ({permissions, ...props}) => (
    <Show title="Task view" {...props}>
        <SimpleShowLayout>
            <TextField label="ID#" source="id"/>
            <CustomMachineField addLabel label="Machine" source="machine_id" reference="machines" />
            <TextField source="machine_barcode"/>
            <ReferenceField label="Fuel container type" source="fuel_container.id" reference="fuel-containers"
                            linkType="show" allowEmpty>
                <TextField source="type"/>
            </ReferenceField>
            <ReferenceManyField label="Fluids used" target="task_id" reference="used-fluids">
                <Datagrid>
                    <TextField label="Fluid type" source="fluid_type.name"/>
                    <TextField label="Fluid amount" source="amount"/>
                </Datagrid>
            </ReferenceManyField>
            {permissions === 'admin' &&
            <ReferenceField label="Employee" source="employee.id" reference="employees" linkType="show" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>}
            <BooleanField source="greasing" />
            <NumberField source="longitude" />
            <NumberField source="latitude" />
            <DateField showTime source="time_open"/>
            <DateField showTime source="time_close"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);
