import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    BooleanField,
    ReferenceField,
    TextInput,
    BooleanInput,
    DisabledInput,
    ReferenceInput,
    ShowButton,
    EditButton,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    AutocompleteInput,
    downloadCSV
} from 'react-admin';

import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const dataForExport = entities.map(entity => {
        entity.job_name = entity.job.name;
        return entity;
    });

    const csv = convertToCSV({
        data: dataForExport,
        fields: ['id', 'address', 'need_fuel', 'job_name', 'created_at', 'updated_at']
    });

    downloadCSV(csv, 'jobsites_' + dateFormat);
};

export const JobsiteList = props => (
    <List {...props} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="address"/>
            <BooleanField source="need_fuel"/>
            <ReferenceField label="Job name" source="job_id" reference="jobs" linkType="show">
                <TextField source="name"/>
            </ReferenceField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const JobsiteShow = (props) => (
    <Show title="Jobsite view" {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="address"/>
            <BooleanField source="need_fuel"/>
            <ReferenceField label="Job name" source="job.id" reference="jobs" linkType="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceManyField label="Machines" reference="machines" target="jobsite_id">
                <SingleFieldList linkType="show">
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceManyField>
            <ReferenceManyField label="Routes" reference="routes" target="jobsites.id">
                <SingleFieldList linkType="show">
                    <ChipField source="time_started"/>
                </SingleFieldList>
            </ReferenceManyField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const JobsiteCreate = (props) => (
    <Create title="Create jobsite" {...props}>
        <SimpleForm>
            <TextInput source="address"/>
            <BooleanInput source="need_fuel"/>
            <ReferenceInput label="Job name" source="job_id" reference="jobs">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const JobsiteEdit = (props) => (
    <Edit title="Jobsite edit" {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="address"/>
            <BooleanInput source="need_fuel"/>
            <ReferenceInput label="Job name" source="job.id" reference="jobs">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleForm>
    </Edit>
);
