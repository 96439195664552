import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {showNotification} from 'react-admin';
import {push} from 'react-router-redux';
import CONFIG from "../config";

const API_URL = CONFIG.backendUrl;

class FuelUsedReportForJobButton extends Component {
    handleClick = () => {
        const {record, showNotification} = this.props;

        fetch(`${API_URL}/jobs/${record.id}/fluid-consumption-report`, {credentials: 'include'})
            .then((response) => {
                showNotification('Report generated');
                // push('/');
            })
            .catch((e) => {
                console.error(e);
                showNotification('Error: comment not approved', 'warning')
            });
    };

    render() {
        return <Button label="Approve" onClick={this.handleClick}>Generate report</Button>;
    }
}

FuelUsedReportForJobButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(null, {
    showNotification,
    push
})(FuelUsedReportForJobButton);
