import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    TextInput,
    DisabledInput,
    ShowButton,
    EditButton,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

import FuelUsedReportForJobButton from './FuelUsedReportForJobButton'

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const csv = convertToCSV({
        data: entities,
        fields: ['id', 'name', 'company_name', 'created_at', 'updated_at']
    });

    downloadCSV(csv, 'jobs_' + dateFormat);
};

export const JobList = ({permissions, ...props}) => (
    <List {...props} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid>
            <TextField label="Name" source="name"/>
            <TextField label="Company name" source="company_name"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <FuelUsedReportForJobButton label="Last month fuel used"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const JobShow = ({permissions, ...props}) => (
    <Show title="Job view" {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="company_name"/>
            {permissions === 'admin' &&
            <ReferenceManyField label="Employees" reference="employees" target="jobs.id">
                <SingleFieldList linkType="show">
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceManyField>}
            <ReferenceManyField label="Jobsites" reference="jobsites" target="job_id">
                <SingleFieldList linkType="show">
                    <ChipField source="address"/>
                </SingleFieldList>
            </ReferenceManyField>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const JobCreate = ({permissions, ...props}) => (
    <Create title="Job task" {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="company_name"/>
        </SimpleForm>
    </Create>
);

export const JobEdit = ({permissions, ...props}) => (
    <Edit title="Job edit" {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="name"/>
            <TextInput source="company_name"/>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleForm>
    </Edit>
);
