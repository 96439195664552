import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    ShowButton,
    EditButton,
    SingleFieldList,
    ReferenceManyField,
    downloadCSV
} from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import moment from "moment";
import {TasksFilter} from './filter';
import CustomMachineField from "./customMachineField";

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const dataForExport = entities.map(entity => {
        if (entity.employee) {
            entity.employee_name = entity.employee.name;
        }

        if (entity.machine) {
            entity.job_name = entity.machine.jobsite.job.name;
            entity.jobsite_address = entity.machine.jobsite.address;
            entity.machine_name = entity.machine.name;
        }

        if (entity.fuel_container) {
            entity.fuel_container_type = entity.fuel_container.type;
        }

        entity.used_fluids.map(uf => {
            entity['used_fluids_' + uf.fluid_type.slug] = uf.amount;
            return uf;
        });

        return entity;
    });

    const csv = convertToCSV({
        data: dataForExport,
        fields: ['id', 'employee_name', 'job_name',
            'jobsite_address', 'machine_name', 'fuel_container_type',
            'latitude', 'longitude',
            'used_fluids_motor_oil', 'used_fluids_def', 'used_fluids_hydraulic_oil',
            'used_fluids_antifreeze', 'used_fluids_off_road_diesel',
            'time_open', 'time_close', 'created_at', 'updated_at'
        ]
    });

    downloadCSV(csv, 'tasks_' + dateFormat);
};

export const TaskList = ({permissions, ...props}) => (
    <List {...props} title="List of tasks" filters={<TasksFilter/>} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            {permissions === 'admin' &&
            <ReferenceField label="Employee" source="employee_id" reference="employees" linkType="show"
                            allowEmpty>
                <TextField source="name"/>
            </ReferenceField>}
            <ReferenceField label="Job" source="machine.jobsite.job_id" reference="jobs" linkType="show"
                            allowEmpty
                            sortable={false}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField label="Jobsite" source="machine.jobsite_id" reference="jobsites" linkType="show"
                            allowEmpty
                            sortable={false}>
                <TextField source="address"/>
            </ReferenceField>
            <CustomMachineField label="Machine" source="machine_id" reference="machines"/>
            <ReferenceField label="Fuel container type" source="fuel_container_id"
                            reference="fuel-containers"
                            linkType="show" allowEmpty>
                <TextField source="type"/>
            </ReferenceField>
            <ReferenceManyField label="Off Road Diesel" reference="used-fluids" target="task_id"
                                filter={{"fluid_type": {"name": 'Off Road Diesel'}}}>
                <SingleFieldList linkType="show">
                    <TextField source="amount"/>
                </SingleFieldList>
            </ReferenceManyField>
            <DateField showTime source="time_open"/>
            <DateField showTime source="time_close"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);
