import React from "react";
import {
    ReferenceInput,
    Filter,
    AutocompleteInput,
    DateTimeInput
} from 'react-admin';

export const TasksFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Employee" source="employee_id" reference="employees" target="employee_id">
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Machine" source="machine_id" reference="machines" target="machine_id">
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Jobsite" source="machine.jobsite_id" reference="jobsites" target="machine.jobsite_id">
            <AutocompleteInput optionText="address"/>
        </ReferenceInput>
        <ReferenceInput label="Fuel container" source="fuel_container_id" reference="fuel-containers"
                        target="fuel_container_id">
            <AutocompleteInput optionText="type"/>
        </ReferenceInput>
        <DateTimeInput source="from_date" label="From date" />
        <DateTimeInput source="to_date" label="To date" />
    </Filter>
);
