import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    TextInput,
    DisabledInput,
    ReferenceInput,
    ReferenceField,
    AutocompleteInput,
    Filter
} from 'react-admin';

const UsedFluidsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Employee" source="task.employee_id" reference="employees">
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Machine" source="task.machine_id" reference="machines">
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Jobsite" source="task.jobsite_id" reference="jobsites">
            <AutocompleteInput optionText="address"/>
        </ReferenceInput>
        <ReferenceInput label="Job" source="task.job_id" reference="jobs">
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);

export const UsedFluidList = props => (
    <List {...props} filters={<UsedFluidsFilter/>}>
        <Datagrid>
            <DateField label="Date" source="created_at"/>
            <TextField label="Task" source="task_id"/>
            <ReferenceField label="Machine" reference="tasks" source="task_id" linkType="show">
                <TextField source="machine.name"/>
            </ReferenceField>
            <TextField label="Fluid type" source="fluid_type.name"/>
            <TextField label="Fluid amount" source="amount"/>
        </Datagrid>
    </List>
);

export const UsedFluidShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField label="Amount" source="amount"/>
            <TextField label="Type" source="fluid_type.name"/>
            <ReferenceField label="Jobsite" reference="tasks" source="task_id" linkType="show">
                <TextField source="jobsite.address"/>
            </ReferenceField>
            <ReferenceField label="Employee" reference="tasks" source="task_id" linkType="show">
                <TextField source="employee.name"/>
            </ReferenceField>
            <ReferenceField label="Machine" reference="tasks" source="task_id" linkType="show">
                <TextField source="machine.name"/>
            </ReferenceField>
            <ReferenceField label="Fuel container type" reference="tasks" source="task_id" linkType="show">
                <TextField source="fuel_container.type"/>
            </ReferenceField>
            <ReferenceField label="Job" reference="tasks" source="task_id" linkType="show">
                <TextField source="job.name"/>
            </ReferenceField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const UsedFluidEdit = (props) => (
    <Edit title="Edit" {...props}>
        <SimpleForm redirect={false} >
            <DisabledInput source="id"/>
            <TextInput label="Amount" source="amount"/>
            <TextField label="Type" source="fluid_type.name"/>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleForm>
    </Edit>
);
