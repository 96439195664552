import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    TextInput,
    DisabledInput,
    ShowButton,
    EditButton,
    ReferenceInput,
    ReferenceField,
    SingleFieldList,
    ReferenceManyField,
    AutocompleteInput,
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const dataForExport = entities.map(entity => {
        entity.jobsite_address = entity.jobsite.address;
        return entity;
    });

    const csv = convertToCSV({
        data: dataForExport,
        fields: ['id', 'barcode', 'serial', 'name', 'type', 'jobsite_address', 'created_at', 'updated_at']
    });

    downloadCSV(csv, 'machines_' + dateFormat);
};

export const MachineList = props => (
    <List {...props} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="barcode"/>
            <TextField source="serial"/>
            <TextField source="type"/>
            <ReferenceField label="Jobsite address" source="jobsite_id" reference="jobsites" linkType="show">
                <TextField source="address"/>
            </ReferenceField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const MachineShow = (props) => (
    <Show title="Machine view" {...props}>
        <SimpleShowLayout>
            <TextField label="ID#" source="id"/>
            <TextField source="name"/>
            <TextField source="barcode"/>
            <TextField source="serial"/>
            <TextField source="type"/>
            <ReferenceField label="Jobsite address" source="jobsite.id" reference="jobsites" linkType="show">
                <TextField source="address"/>
            </ReferenceField>
            <ReferenceManyField label="Tasks" target="machine_id" reference="tasks">
                <Datagrid>
                    <DateField label="Date Created" source="created_at"/>
                    <ReferenceManyField label="Off Road Diesel" reference="used-fluids" target="task_id"
                                        filter={{"fluid_type.name": 'Off Road Diesel'}}>
                        <SingleFieldList linkType="show">
                            <TextField source="amount"/>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <ReferenceManyField label="DEF" reference="used-fluids" target="task_id"
                                        filter={{"fluid_type.name": 'DEF'}}>
                        <SingleFieldList linkType="show">
                            <TextField source="amount"/>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <ReferenceManyField label="Hydraulic Oil" reference="used-fluids" target="task_id"
                                        filter={{"fluid_type.name": 'Hydraulic Oil'}}>
                        <SingleFieldList linkType="show">
                            <TextField source="amount"/>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <ReferenceManyField label="Motor Oil" reference="used-fluids" target="task_id"
                                        filter={{"fluid_type.name": 'Motor Oil'}}>
                        <SingleFieldList linkType="show">
                            <TextField source="amount"/>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <ReferenceManyField label="Antifreeze" reference="used-fluids" target="task_id"
                                        filter={{"fluid_type.name": 'Antifreeze'}}>
                        <SingleFieldList linkType="show">
                            <TextField source="amount"/>
                        </SingleFieldList>
                    </ReferenceManyField>
                </Datagrid>
            </ReferenceManyField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const MachineCreate = (props) => (
    <Create title="Create machine" {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="barcode"/>
            <TextInput source="serial"/>
            <TextInput source="type"/>
            <ReferenceInput label="Jobsite address" source="jobsite_id" reference="jobsites">
                <AutocompleteInput optionText="address"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const MachineEdit = (props) => (
    <Edit title="Machine edit" {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput source="name"/>
            <TextInput source="barcode"/>
            <TextInput source="serial"/>
            <TextInput source="type"/>
            <ReferenceInput label="Jobsite address" source="jobsite.id" reference="jobsites">
                <AutocompleteInput optionText="address"/>
            </ReferenceInput>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleForm>
    </Edit>
);
