import React from 'react';
import {ReferenceField, TextField, FunctionField} from 'react-admin';
import PropTypes from 'prop-types';

const CustomMachineField = ({ resource, source, basePath, reference, record = {}, allowEmpty, ...rest}) => {
    if (record.machine_barcode) {
        return (
            <FunctionField label="Machine" resource={resource} source={source} record={record} reference={reference} render={() => `Missing machine!`} />
        )
    }

    return (
        <ReferenceField label="Machine" resource={resource} source={source} record={record} reference={reference}
                        basePath="/machines" linkType="show"
                        allowEmpty={allowEmpty}>
            <TextField source="name"/>
        </ReferenceField>
    )
};

CustomMachineField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    basePath: PropTypes.string,
    reference: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
};

export default CustomMachineField;
