import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    NumberField,
    TextInput,
    NumberInput,
    DisabledInput,
    ShowButton,
    EditButton,
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const csv = convertToCSV({
        data: entities,
        fields: ['id', 'total_capacity', 'active_capacity', 'barcode', 'type', 'created_at', 'updated_at']
    });

    downloadCSV(csv, 'fuel-containers_' + dateFormat);
};

export const FuelContainerList = props => (
    <List {...props} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField label="Type" source="type"/>
            <NumberField label="Total capacity" source="total_capacity"/>
            <NumberField label="Active capacity" source="active_capacity"/>
            <TextField label="Barcode" source="barcode"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const FuelContainerShow = (props) => (
    <Show title="Fuel container view" {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <TextField source="id"/>
            <TextField label="Type" source="type"/>
            <NumberField label="Total capacity" source="total_capacity"/>
            <NumberField label="Active capacity" source="active_capacity"/>
            <TextField label="Barcode" source="barcode"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const FuelContainerCreate = (props) => (
    <Create title="Create fuel container" {...props}>
        <SimpleForm>
            <TextInput label="Type" source="type"/>
            <NumberInput label="Total capacity" source="total_capacity"/>
            <NumberInput label="Active capacity" source="active_capacity"/>
            <TextInput label="Barcode" source="barcode"/>
        </SimpleForm>
    </Create>
);

export const FuelContainerEdit = (props) => (
    <Edit title="Fuel container edit" {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <TextInput label="Type" source="type"/>
            <NumberInput label="Total capacity" source="total_capacity"/>
            <NumberInput label="Active capacity" source="active_capacity"/>
            <TextInput label="Barcode" source="barcode"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleForm>
    </Edit>
);
