import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS} from 'react-admin';
import CONFIG from './config';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const {username, password} = params;
        const request = new Request(`${CONFIG.backendUrl}/auth/login`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({email: username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then((response) => {
                localStorage.setItem('username', params.username);
                localStorage.setItem('role', response.role);
            });
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('username');

        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        return localStorage.getItem('username') ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401) {
            return Promise.reject();
        }

        return Promise.resolve();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role');

        return role ? Promise.resolve(role) : Promise.reject();
    }
}
