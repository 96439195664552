import React, {Component, Fragment} from 'react';
import {
    Button
} from 'react-admin';
import IconContentEdit from '@material-ui/icons/Edit';
import Drawer from '@material-ui/core/Drawer';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {UsedFluidEdit} from "../usedFluids";

export class EditUsedFluidsButton extends Component {
    state = {showPanel: false};

    handleClick = () => {
        this.setState({showPanel: true});
    };

    handleCloseClick = () => {
        this.setState({showPanel: false});
    };

    render() {
        const {showPanel} = this.state;
        const id = this.props.record.id;

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="ra.action.edit">
                    <IconContentEdit/>
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Close" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight/>
                        </Button>
                    </div>
                    <UsedFluidEdit id={id} basePath="/used-fluids" resource="used-fluids" />
                </Drawer>
            </Fragment>
        );
    }
}
