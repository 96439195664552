import React from "react";
import {
    Datagrid,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    DateTimeInput,
    DisabledInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceManyField,
    NumberField,
    NumberInput,
    BooleanInput
} from 'react-admin';
import {EditUsedFluidsButton} from "./editUsedFluidsButton";

export const TaskEdit = ({permissions, ...props}) => (
    <Edit title="Task edit" {...props} undoable={false}>
        <SimpleForm>
            <DisabledInput source="id"/>
            <DateTimeInput source="time_open"/>
            <DateTimeInput source="time_close"/>
            <NumberInput source="longitude"/>
            <NumberInput source="latitude"/>
            <ReferenceInput label="Machine" source="machine.id" reference="machines" allowEmpty>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            {permissions === 'admin' &&
            <ReferenceInput label="Employee" source="employee.id" reference="employees" allowEmpty>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>}
            <ReferenceInput label="Fuel container type" source="fuel_container.id" reference="fuel-containers"
                            allowEmpty>
                <AutocompleteInput optionText="type"/>
            </ReferenceInput>
            <BooleanInput source="greasing"/>
            <ReferenceManyField label="Fluids used" target="task_id" reference="used-fluids">
                <Datagrid>
                    <TextField label="Fluid type" source="fluid_type.name"/>
                    <NumberField label="Fluid amount" source="amount"/>
                    <EditUsedFluidsButton/>
                </Datagrid>
            </ReferenceManyField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleForm>
    </Edit>
);
