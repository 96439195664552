import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    DateTimeInput,
    DisabledInput,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    ShowButton,
    EditButton,
    ReferenceManyField,
    ChipField,
    SingleFieldList,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    downloadCSV
} from 'react-admin';

import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const dataForExport = entities.map(entity => {
        entity.employee_name = entity.employee.name;
        entity.job_name = entity.job.name;
        entity.calendar_date = entity.calendar.date;
        return entity;
    });

    const csv = convertToCSV({
        data: dataForExport,
        fields: [
            'id', 'time_started', 'time_closed',
            'job_name', 'employee_name', 'calendar_date',
            'created_at', 'updated_at'
        ]
    });

    downloadCSV(csv, 'routes_' + dateFormat);
};

export const RouteList = ({permissions, ...props}) => (
    <List {...props} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <DateField showTime source="time_started"/>
            <DateField showTime source="time_closed"/>
            <ReferenceField label="Job name" source="job_id" reference="jobs" linkType="show">
                <TextField source="name"/>
            </ReferenceField>
            {permissions === 'admin' &&
            <ReferenceField label="Employee" source="employee_id" reference="employees" linkType="show">
                <TextField source="name"/>
            </ReferenceField>}
            <ReferenceField label="Calendar date" source="calendar_scheduling_id" reference="calendar-scheduling" linkType="show">
                <DateField showTime source="date"/>
            </ReferenceField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const RouteShow = ({permissions, ...props}) => (
    <Show title="Route view" {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <DateField showTime source="time_started"/>
            <DateField showTime source="time_closed"/>
            <ReferenceField label="Job name" source="job.id" reference="jobs" linkType="show">
                <TextField source="name"/>
            </ReferenceField>
            {permissions === 'admin' &&
            <ReferenceField label="Employee" source="employee.id" reference="employees" linkType="show">
                <TextField source="name"/>
            </ReferenceField>}
            <ReferenceField label="Calendar date" source="calendar.id" reference="calendar-scheduling" linkType="show">
                <DateField showTime source="date"/>
            </ReferenceField>
            <ReferenceManyField label="Route order" reference="jobsites" source="route_order" target="id">
                <SingleFieldList linkType="show">
                    <ChipField source="address"/>
                </SingleFieldList>
            </ReferenceManyField>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const RouteCreate = ({permissions, ...props}) => (
    <Create title="Create route" {...props}>
        <SimpleForm>
            <DateTimeInput source="time_started"/>
            <DateTimeInput source="time_closed"/>
            <ReferenceInput label="Job name" source="job_id" reference="jobs">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            {permissions === 'admin' &&
            <ReferenceInput label="Employee" source="employee_id" reference="employees">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>}
            <ReferenceArrayInput label="Route order" source="route_order" reference="jobsites">
                <AutocompleteArrayInput optionText="address" />
            </ReferenceArrayInput>
            <ReferenceInput label="Calendar scheduling" source="calendar_scheduling_id" reference="calendar-scheduling">
                <AutocompleteInput optionText="date"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const RouteEdit = ({permissions, ...props}) => (
    <Edit title="Route edit" {...props}>
        <SimpleForm redirect={false}>
            <DisabledInput source="id"/>
            <DateTimeInput source="time_started"/>
            <DateTimeInput source="time_closed"/>
            <ReferenceInput label="Job name" source="job.id" reference="jobs">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            {permissions === 'admin' &&
            <ReferenceInput label="Employee" source="employee.id" reference="employees">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>}
            <ReferenceArrayInput label="Route order" source="route_order" reference="jobsites">
                <AutocompleteArrayInput optionText="address" />
            </ReferenceArrayInput>
            <ReferenceInput label="Calendar scheduling" source="calendar_scheduling_id" reference="calendar-scheduling">
                <AutocompleteInput optionText="date"/>
            </ReferenceInput>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
        </SimpleForm>
    </Edit>
);
