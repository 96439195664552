import React from 'react';
import {Admin, Resource} from 'react-admin';

import {EmployeeList, EmployeeShow, EmployeeCreate, EmployeeEdit} from './components/employees';
import {CalendarList, CalendarShow, CalendarCreate, CalendarEdit} from './components/calendars';
import {UserList, UserShow, UserCreate, UserEdit} from './components/users';
import {JobList, JobShow, JobCreate, JobEdit} from './components/jobs';
import {
    FuelContainerList,
    FuelContainerShow,
    FuelContainerCreate,
    FuelContainerEdit
} from './components/fuelContainers';
import {JobsiteList, JobsiteShow, JobsiteCreate, JobsiteEdit} from './components/jobsites';
import {MachineList, MachineShow, MachineCreate, MachineEdit} from './components/machines';
import {RouteList, RouteShow, RouteCreate, RouteEdit} from './components/routes';
import {TaskList, TaskShow, TaskCreate, TaskEdit} from './components/tasks';
import {UsedFluidList, UsedFluidShow, UsedFluidEdit} from './components/usedFluids';

import Dashboard from './Dashboard';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

const App = () => (
    <Admin authProvider={authProvider} dashboard={Dashboard} dataProvider={dataProvider}>
        {permissions => [
            permissions === 'admin' ?
                <Resource name="users"
                          list={UserList}
                          show={UserShow}
                          create={UserCreate}
                          edit={UserEdit}
                /> : null,
            permissions === 'admin' ?
                <Resource name="employees"
                          list={EmployeeList}
                          show={EmployeeShow}
                          create={EmployeeCreate}
                          edit={EmployeeEdit}
                /> : null,
            <Resource name="tasks"
                      list={TaskList}
                      show={TaskShow}
                      create={TaskCreate}
                      edit={TaskEdit}
            />,
            <Resource name="jobs"
                      list={JobList}
                      show={JobShow}
                      create={JobCreate}
                      edit={JobEdit}
            />,
            <Resource name="calendar-scheduling"
                      list={CalendarList}
                      show={CalendarShow}
                      create={CalendarCreate}
                      edit={CalendarEdit}
            />,
            <Resource name="jobsites"
                      list={JobsiteList}
                      show={JobsiteShow}
                      create={JobsiteCreate}
                      edit={JobsiteEdit}
            />,
            <Resource name="routes"
                      list={RouteList}
                      show={RouteShow}
                      create={RouteCreate}
                      edit={RouteEdit}
            />,
            <Resource name="fuel-containers"
                      list={permissions === 'admin' ? FuelContainerList : null}
                      show={FuelContainerShow}
                      create={permissions === 'admin' ? FuelContainerCreate : null}
                      edit={permissions === 'admin' ? FuelContainerEdit : null}
            />,
            <Resource name="machines"
                      list={permissions === 'admin' ? MachineList : null}
                      show={MachineShow}
                      create={permissions === 'admin' ? MachineCreate : null}
                      edit={permissions === 'admin' ? MachineEdit : null}
            />,
            <Resource name="used-fluids" />
        ]}
    </Admin>
);

export default App;
