import React from 'react';
import {
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    Create,
    Edit,
    SimpleForm,
    TextField,
    DateField,
    NumberField,
    ReferenceField,
    DateTimeInput,
    NumberInput,
    DisabledInput,
    ShowButton,
    EditButton,
    ReferenceInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    AutocompleteInput,
    downloadCSV
} from 'react-admin';

import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = entities => {
    let date = new Date();
    let dateFormat = moment(date).format("YYYYMMDDHHmmss");

    const dataForExport = entities.map(entity => {
        entity.employee_name = entity.employee.name;
        return entity;
    });

    const csv = convertToCSV({
        data: dataForExport,
        fields: ['id', 'date', 'time_length', 'employee_name', 'created_at', 'updated_at']
    });

    downloadCSV(csv, 'calendars_' + dateFormat);
};

export const CalendarList = ({permissions, ...props}) => (
    <List {...props} exporter={exporter} sort={{ field: 'created_at', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            {permissions === 'admin' &&
            <ReferenceField label="Employee" source="employee_id" reference="employees" linkType="show">
                <TextField source="name"/>
            </ReferenceField>}
            <DateField showTime source="date"/>
            <NumberField source="time_length"/>
            <DateField showTime label="Updated at" source="updated_at"/>
            <DateField showTime label="Created at" source="created_at"/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const CalendarShow = ({permissions, ...props}) => (
    <Show title="Calendar view" {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            {permissions === 'admin' &&
            <ReferenceField label="Employee" source="employee_id" reference="employees" linkType="show">
                <TextField source="name"/>
            </ReferenceField>}
            <DateField showTime source="date"/>
            <NumberField source="time_length"/>
            <ReferenceManyField label="Routes" reference="routes" target="calendar_scheduling_id">
                <SingleFieldList linkType="show">
                    <ChipField source="time_started"/>
                </SingleFieldList>
            </ReferenceManyField>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleShowLayout>
    </Show>
);

export const CalendarCreate = ({permissions, ...props}) => (
    <Create title="Create calendar" {...props}>
        <SimpleForm>
            {permissions === 'admin' &&
            <ReferenceInput label="Employee" source="employee_id" reference="employees">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>}
            <DateTimeInput source="date"/>
            <NumberInput source="time_length"/>
        </SimpleForm>
    </Create>
);

export const CalendarEdit = ({permissions, ...props}) => (
    <Edit title="Calendar edit" {...props}>
        <SimpleForm>
            <DisabledInput source="id"/>
            {permissions === 'admin' &&
            <ReferenceInput label="Employee" source="employee_id" reference="employees">
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>}
            <DateTimeInput source="date"/>
            <NumberInput source="time_length"/>
            <DateField showTime source="updated_at"/>
            <DateField showTime source="created_at"/>
        </SimpleForm>
    </Edit>
);
